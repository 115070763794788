// api.js
import axios from 'axios';

// Update API_URL to point to your backend server on port 8200
const API_URL = 'https://api.developiq.org/api';

export const signUp = async (formData) => {
  try {
    const response = await axios.post(API_URL, {
      m: 'signUp',
      uname: formData.uname,
      email: formData.email,
      pword: formData.password,
    });
    return response.data;
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export const login = async (email, password) => {
  try {
    const response = await axios.post(API_URL, {
      m: 'login',
      email,
      password,
    });
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const searchListings = async (searchParams) => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('User not authenticated');
  }
  try {
    const response = await axios.post(API_URL, {
      m: 'searchListings',
      ...searchParams,
      tok: token,
    });
    return response.data;
  } catch (error) {
    console.error('Error searching listings:', error);
    throw error;
  }
};

export const getFullListing = async (params) => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('User not authenticated');
  }
  try {
    const response = await axios.post(API_URL, {
      m: 'getFullListing',
      ...params,
      tok: token,
    });
    return response.data;
  } catch (error) {
    console.error('Error getting full listing:', error);
    throw error;
  }
};

export const getUserStatus = async (token) => {
  try {
    const response = await axios.post(API_URL, {
      m: 'getUserStatus',
      tok: token,
    });
    return response.data.status;
  } catch (error) {
    console.error('Error fetching user status:', error);
    throw error;
  }
};

export const sendResetEmail = async (email) => {
  try {
    const response = await axios.post(API_URL, {
      m: 'sendResetEmail',
      email: email,
    });
    return response.data.status;
  } catch (error) {
    console.error('Error fetching user status:', error);
    throw error;
  }
};

export const verifyCode = async (email, codeEntered) => {
  try {
    const response = await axios.post(API_URL, {
      m: 'verifyCode',
      email: email,
      code: codeEntered
    });
    return response.data.status;
  } catch (error) {
    console.error('Error fetching user status:', error);
    throw error;
  }
};

export const resetPassword = async (email, newPassword) => {
  try {
    const response = await axios.post(API_URL, {
      m: 'resetPassword',
      email: email,
      newPassword: newPassword
    });
    return response.data.status;
  } catch (error) {
    console.error('Error fetching user status:', error);
    throw error;
  }
};