// PaymentSuccess.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect to home page after 3 seconds
        const timer = setTimeout(() => navigate('/'), 3000);
        return () => clearTimeout(timer);  // Clear timeout on component unmount
    }, [navigate]);

    return (
        <div style={{ textAlign: 'center', padding: '50px' }}>
            <h1>Payment Successful!</h1>
            <p>Thank you for your payment. You will be redirected to the home page shortly.</p>
        </div>
    );
};

export default PaymentSuccess;
