import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { sendResetEmail, verifyCode, resetPassword } from '../api'; // Placeholder for your API functions
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1); // 1: enter email, 2: enter code, 3: reset password
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  
  const navigate = useNavigate(); // Initialize navigate function

  const handleSendEmail = async () => {
    try {
      await sendResetEmail(email); // Call the API to send the email
      setError(null); // Clear any previous error
      setMessage('Reset code sent to your email. Please check your inbox.');
      setStep(2); // Move to the next step (enter code)
    } catch (err) {
      setError(err.response?.data?.m || 'An error occurred. Please try again.');
    }
  };

  const handleVerifyCode = async () => {
    try {
      await verifyCode(email, code); // Verify the reset code
      setError(null); // Clear any previous error
      setMessage('Code verified. You can now reset your password.');
      setStep(3); // Move to the password reset step
    } catch (err) {
      setError(err.response?.data?.m || 'Invalid code. Please try again.');
      setMessage(null); // Clear any previous success message
    }
  };

  const handleResetPassword = async () => {
    try {
      await resetPassword(email, newPassword); // Reset the password
      setError(null); // Clear any previous error
      setMessage('Password reset successfully. You can now sign in.');
      setStep(1); // Reset the step state
      navigate('/signin'); // Redirect to the sign-in page after successful password reset
    } catch (err) {
      setError(err.response?.data?.m || 'An error occurred. Please try again.');
    }
  };

  return (
    <Container maxWidth="xs" className="forgot-password-container">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>

        {error && <Typography color="error">{error}</Typography>}
        {message && <Typography color="primary">{message}</Typography>}

        {step === 1 && (
          <>
            <Typography variant="body1" style={{ marginTop: '1rem' }}>
              Enter your email address to receive a password reset code.
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Update email state
            />
            <Button
              type="submit"
              fullWidth
              sx={{
                marginTop: 2,
                backgroundColor: 'goldenrod',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'grey',
                },
              }}
              onClick={handleSendEmail}
            >
              Send Email
            </Button>
          </>
        )}

        {step === 2 && (
          <>
            <Typography variant="body1" style={{ marginTop: '1rem' }}>
              Enter the code sent to your email.
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="code"
              name="code"
              placeholder="Enter Code"
              value={code}
              onChange={(e) => setCode(e.target.value)} // Update code state
            />
            <Button
              type="submit"
              fullWidth
              sx={{
                marginTop: 2,
                backgroundColor: 'goldenrod',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'grey',
                },
              }}
              onClick={handleVerifyCode}
            >
              Verify Code
            </Button>
          </>
        )}

        {step === 3 && (
          <>
            <Typography variant="body1" style={{ marginTop: '1rem' }}>
              Enter your new password.
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="new-password"
              name="new-password"
              type="password"
              placeholder="Enter New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)} // Update newPassword state
            />
            <Button
              type="submit"
              fullWidth
              sx={{
                marginTop: 2,
                backgroundColor: 'goldenrod',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'grey',
                },
              }}
              onClick={handleResetPassword}
            >
              Reset Password
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default ForgotPassword;