// ResultPopup.js
import React, { useState, useEffect } from 'react';
import './ResultPopup.css';
import {
  MapContainer,
  TileLayer,
  Marker,
  LayerGroup,
  GeoJSON,
  useMap,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Switch } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import markerIcon from './marker-icon.png';
import axios from 'axios';

// Custom marker icon for the map
const customIcon = new L.Icon({
  iconUrl: markerIcon,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
});

const ResultPopup = ({ place, onClose, userStatus, token }) => {
  const [showUtilities, setShowUtilities] = useState(false);
  const [showTopography, setShowTopography] = useState(false);
  const [sewerData, setSewerData] = useState(null);
  const [waterData, setWaterData] = useState(null);

  useEffect(() => {
    if (place) {
      console.log('Full parcel data:', place);
    }
  }, [place]);

  // Fetch utility data when showUtilities changes
  useEffect(() => {
    if (showUtilities) {
      // Fetch Sewer Main GeoJSON
      fetch('/Sewer_Main.geojson')
        .then((response) => response.json())
        .then((data) => setSewerData(data))
        .catch((error) =>
          console.error('Error fetching Sewer_Main.geojson:', error)
        );

      // Fetch Water Main GeoJSON
      fetch('/Water_Main.geojson')
        .then((response) => response.json())
        .then((data) => setWaterData(data))
        .catch((error) =>
          console.error('Error fetching Water_Main.geojson:', error)
        );
    } else {
      // Clear utility data to remove layers
      setSewerData(null);
      setWaterData(null);
    }
  }, [showUtilities]);

  const handleUtilitiesToggle = (event) => {
    setShowUtilities(event.target.checked);
  };

  const handleTopographyToggle = (event) => {
    setShowTopography(event.target.checked);
  };

  const handlePaymentClick = async () => {
    try {
      const response = await axios.post('https://api.developiq.org/api', {
        m: 'getPaymentURL',
        tok: token,
      });
      const { url } = response.data;
      window.location.href = url;
    } catch (error) {
      console.error('Error creating payment session:', error);
      alert('An error occurred while processing your payment. Please try again.');
    }
  };

  if (!place) return null;

  const primaryPhoto = place.Primary_Photo || '';
  const altPhotos = place.Alt_Photos ? place.Alt_Photos.split(', ') : [];
  const fallbackImage = '/path-to-default-image.jpg'; // Ensure this path is correct

  const renderRestrictedItem = (title) => (
    <div className="info-item-popup restricted-info-item-popup">
      <LockIcon className="lock-icon" />
      <strong className="info-title">{title}</strong>
    </div>
  );

  // Helper function to format numbers to two decimal places
  const formatNumber = (num) => {
    const parsedNum = parseFloat(num);
    if (isNaN(parsedNum)) return 'Currently Unavailable - Refer to your local municipality';
    return parsedNum.toFixed(2);
  };

  // Determine if the user is paid
  const isPaidUser = userStatus === 'PAID' || userStatus === 'ADMIN';

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <div className="popup-top">
          <div className="popup-image-section">
            <img
              src={primaryPhoto || altPhotos[0] || fallbackImage}
              alt={place.Full_Address || 'Unknown Address'}
              className="popup-image"
              onError={(e) => {
                e.target.src = fallbackImage;
              }}
            />
          </div>
          <div className="popup-map-section">
            <MapContainer
              center={[Number(place.Latitude), Number(place.Longitude)]}
              zoom={15}
              style={{ height: '100%', width: '100%' }}
            >
              <MapUpdater
                showUtilities={showUtilities}
                sewerData={sewerData}
                waterData={waterData}
                showTopography={showTopography}
              />
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {/* Topographic Layer */}
              {showTopography && (
                <TileLayer
                  url="https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}"
                  attribution="Map courtesy of the U.S. Geological Survey"
                  maxNativeZoom={16}
                  maxZoom={20}
                />
              )}
              {showUtilities && (
                <LayerGroup>
                  {/* Sewer Main Layer */}
                  {sewerData && (
                    <GeoJSON
                      data={sewerData}
                      style={{ color: 'blue' }}
                      onEachFeature={(feature, layer) => {
                        layer.bindPopup(
                          `Sewer Main: ${feature.properties.name || 'N/A'}`
                        );
                      }}
                    />
                  )}
                  {/* Water Main Layer */}
                  {waterData && (
                    <GeoJSON
                      data={waterData}
                      style={{ color: 'green' }}
                      onEachFeature={(feature, layer) => {
                        layer.bindPopup(
                          `Water Main: ${feature.properties.name || 'N/A'}`
                        );
                      }}
                    />
                  )}
                </LayerGroup>
              )}
              <Marker
                position={[Number(place.Latitude), Number(place.Longitude)]}
                icon={customIcon}
              />
            </MapContainer>
            {/* Utility and Topography Toggles */}
            <div className="toggle-container">
              <div className="toggle-item">
                <span>Utilities</span>
                <Switch checked={showUtilities} onChange={handleUtilitiesToggle} />
              </div>
              <div className="toggle-item">
                <span>Topography</span>
                <Switch checked={showTopography} onChange={handleTopographyToggle} />
              </div>
            </div>
            <div className="legend">
              <p><span className="legend-color legend-water"></span>Water</p>
              <p><span className="legend-color legend-sewer"></span>Sewage</p>
            </div>
          </div>
        </div>
        <h2 className="popup-price">
          $
          {place.List_Price !== undefined
            ? parseFloat(place.List_Price).toLocaleString(undefined, { minimumFractionDigits: 2 })
            : 'Currently Unavailable - Refer to your local municipality'}
        </h2>
        <h3 className="popup-name">{place.Full_Address || 'Unknown Address'}</h3>
        <div className="popup-info">
          <p>
            Acreage:{' '}
            {place.Lot_Acreage
              ? formatNumber(place.Lot_Acreage)
              : 'Currently Unavailable - Refer to your local municipality'}
          </p>
          <p>
            Market Status:{' '}
            {place.Status || 'Currently Unavailable - Refer to your local municipality'}
          </p>
        </div>
        {isPaidUser ? (
          <div className="popup-details">
            {/* Zoning and Utilities Side by Side */}
            <div className="info-sections-row">
              {/* Zoning Section */}
              <div className="info-section">
                <h4>Zoning</h4>
                <div className="info-item-popup">
                  <strong>Zoning Regulations</strong>
                  <p>{place.Zone || 'Currently Unavailable - Refer to your local municipality'}</p>
                </div>
              </div>
              {/* Utilities Section */}
              <div className="info-section">
                <h4>Utilities</h4>
                <div className="info-item-popup">
                  <strong>Sewage Access Distance</strong>
                  <p>
                    {place.Sewage_Dist_Meters
                      ? `${formatNumber(place.Sewage_Dist_Meters)} meters`
                      : 'Currently Unavailable - Refer to your local municipality'}
                  </p>
                </div>
                <div className="info-item-popup">
                  <strong>Water Access Distance</strong>
                  <p>
                    {place.Dist_to_Water
                      ? `${formatNumber(place.Dist_to_Water)} meters`
                      : 'Currently Unavailable - Refer to your local municipality'}
                  </p>
                </div>
              </div>
            </div>
            {/* Other Sections Below */}
            {/* Community Insights Section */}
            <div className="info-section full-width">
              <h4>Community Insights</h4>
              <div className="info-item-popup">
                <strong>Closest Hospital</strong>
                <p>
                  {place.Closest_Hospital ||
                    'Currently Unavailable - Refer to your local municipality'}
                </p>
              </div>
              <div className="info-item-popup">
                <strong>Closest School</strong>
                <p>
                  {place.Nearby_Schools ||
                    'Currently Unavailable - Refer to your local municipality'}
                </p>
              </div>
              <div className="info-item-popup">
                <strong>Closest Religious Building Distance</strong>
                <p>
                  {place.Dist_to_Church
                    ? `${formatNumber(place.Dist_to_Church)} km`
                    : 'Currently Unavailable - Refer to your local municipality'}
                </p>
              </div>
              <div className="info-item-popup">
                <strong>Closest Park</strong>
                <p>
                  {place.Closest_Park ||
                    'Currently Unavailable - Refer to your local municipality'}
                </p>
              </div>
            </div>
            {/* Price Comparison Section */}
            <div className="info-section full-width">
              <h4>Price Comparison</h4>
              <div className="info-item-popup">
                <strong>Median Price Per Acre Sold</strong>
                <p>
                  {place.Median_Price_Per_Acre_Sold
                    ? `${parseFloat(formatNumber(place.Median_Price_Per_Acre_Sold)).toLocaleString()} per acre`
                    : 'Currently Unavailable - Refer to your local municipality'}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="popup-details">
            <p className="pay-to-unlock-message" onClick={handlePaymentClick}>
              Pay here for this information
            </p>
            {/* Zoning and Utilities Side by Side */}
            <div className="info-sections-row">
              {/* Zoning Section */}
              <div className="info-section">
                <h4>Zoning</h4>
                {renderRestrictedItem('Zoning Regulations')}
              </div>
              {/* Utilities Section */}
              <div className="info-section">
                <h4>Utilities</h4>
                {renderRestrictedItem('Sewage Access')}
                {renderRestrictedItem('Water Access')}
              </div>
            </div>
            {/* Other Sections Below */}
            {/* Community Insights Section */}
            <div className="info-section full-width">
              <h4>Community Insights</h4>
              {renderRestrictedItem('Closest Hospital')}
              {renderRestrictedItem('Closest School')}
              {renderRestrictedItem('Closest Religious Building')}
              {renderRestrictedItem('Closest Park')}
            </div>
            {/* Price Comparison Section */}
            <div className="info-section full-width">
              <h4>Price Comparison</h4>
              {renderRestrictedItem('Median Price Per Acre Sold')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Custom component to handle map updates
const MapUpdater = ({ showUtilities, showTopography, sewerData, waterData }) => {
  const map = useMap();

  // Invalidate size when the component mounts
  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
  }, [map]);

  // Update layers when utilities or topography state changes
  useEffect(() => {
    // You can perform any additional layer management here if needed
  }, [showUtilities, showTopography, sewerData, waterData, map]);

  return null;
};

export default ResultPopup;
