import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import MapInterface from './MapInterface';
import ResultPopup from './ResultPopup';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  Switch,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { searchListings, getFullListing } from '../api';
import './SearchResults.css';

const SearchResults = ({ initialSearch }) => {
  const [searchInput, setSearchInput] = useState(initialSearch || '');
  const [zoning, setZoning] = useState('');
  const [minAcreage, setMinAcreage] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [marketStatus, setMarketStatus] = useState('');
  const [searchError, setSearchError] = useState(false);
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [showUtilities, setShowUtilities] = useState(false);
  const [showTopography, setShowTopography] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [userStatus, setUserStatus] = useState('UNPAID');

  const fetchUserStatus = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setUserStatus('UNAUTHENTICATED');
      return;
    }

    try {
      const response = await axios.post('https://api.developiq.org/api', {
        m: 'getUserStatus',
        tok: token,
      });
      const statusCode = response.data.status;

      let status;
      switch (statusCode) {
        case 20:
          status = 'UNVERIFIED';
          break;
        case 30:
          status = 'VERIFIED';
          break;
        case 40:
          status = 'PAID';
          break;
        case 90:
          status = 'ADMIN';
          break;
        default:
          status = 'UNKNOWN';
      }
      setUserStatus(status);
    } catch (error) {
      console.error('Failed to fetch user status:', error);
      setUserStatus('UNAUTHENTICATED');
    }
  }, []);

  useEffect(() => {
    fetchUserStatus();
  }, [fetchUserStatus]);

  const handleSearch = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Please log in to search for properties.');
      setSearchError(true);
      return;
    }

    const searchParams = {
      city: searchInput.trim() || undefined,
      zone: zoning || undefined,
      acres_min: minAcreage ? parseFloat(minAcreage) : undefined,
      price_max: maxPrice ? parseFloat(maxPrice) : undefined,
      market_status: marketStatus || undefined,
    };

    try {
      const response = await searchListings(searchParams, token);
      const results = response || [];
      setFilteredPlaces(Array.isArray(results) ? results : []);
      setSearchError(false);
    } catch (error) {
      console.error('Search failed:', error);
      setFilteredPlaces([]);
      setSearchError(true);
    }
  }, [searchInput, zoning, minAcreage, maxPrice, marketStatus]);

  useEffect(() => {
    if (initialSearch && firstTime) {
      setSearchInput(initialSearch);
      handleSearch();
      setFirstTime(false);
    }
  }, [initialSearch, handleSearch, firstTime]);

  useEffect(() => {
    if (selectedPlace) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [selectedPlace]);

  const handlePlaceClick = async (place) => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Please log in to view property details.');
      return;
    }

    try {
      const response = await axios.post('https://api.developiq.org/api', {
        m: 'getFullListing',
        h: place.h,
        tok: token,
      });
      const fullDetails = response.data;
      setSelectedPlace({ ...place, ...fullDetails });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert('You need a paid account to view full property details.');
      } else {
        console.error('Failed to get full listing:', error.response?.data || error.message);
      }
    }
  };

  const handleClosePopup = () => {
    setSelectedPlace(null);
  };

  const handleUtilitiesToggle = (event) => {
    setShowUtilities(event.target.checked);
  };

  const handleTopographyToggle = (event) => {
    setShowTopography(event.target.checked);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="search-results-container">
      <div className="search-bar-container">
        {/* Search Inputs and Filters */}
        <TextField
          placeholder="Enter a city"
          variant="outlined"
          fullWidth
          value={searchInput}
          onKeyDown={handleKeyDown}
          onChange={(e) => setSearchInput(e.target.value)}
          InputProps={{
            style: {
              backgroundColor: 'white',
              borderRadius: '25px',
              paddingRight: '8px',
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: {
              color: 'black',
            },
          }}
        />
        {/* Zoning Filter */}
        <FormControl variant="outlined" style={{ marginLeft: '10px', minWidth: '150px' }}>
          <InputLabel>Zoning</InputLabel>
          <Select
            value={zoning}
            onChange={(e) => setZoning(e.target.value)}
            label="Zoning"
          >
            <MenuItem value="">
              <em>Any</em>
            </MenuItem>
            <MenuItem value="Residential">Residential</MenuItem>
            <MenuItem value="Commercial">Commercial</MenuItem>
            <MenuItem value="Industrial">Industrial</MenuItem>
          </Select>
        </FormControl>
        {/* Min Acreage Filter */}
        <FormControl variant="outlined" style={{ marginLeft: '10px', minWidth: '150px' }}>
          <TextField
            label="Min Acreage"
            variant="outlined"
            type="number"
            value={minAcreage}
            onChange={(e) => setMinAcreage(e.target.value)}
          />
        </FormControl>
        {/* Max Price Filter */}
        <FormControl variant="outlined" style={{ marginLeft: '10px', minWidth: '150px' }}>
          <TextField
            label="Max Price"
            variant="outlined"
            type="number"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
          />
        </FormControl>
        {/* Market Status Filter */}
        <FormControl variant="outlined" style={{ marginLeft: '10px', minWidth: '150px' }}>
          <InputLabel>Market Status</InputLabel>
          <Select
            value={marketStatus}
            onChange={(e) => setMarketStatus(e.target.value)}
            label="Market Status"
          >
            <MenuItem value="">
              <em>Any</em>
            </MenuItem>
            <MenuItem value="on-market">On Market</MenuItem>
            <MenuItem value="off-market">Off Market</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="content-container">
        <div className="map-container">
          <MapInterface
            places={filteredPlaces}
            showUtilities={showUtilities}
            showTopography={showTopography}
            onMarkerClick={handlePlaceClick}
          />
          <div className="toggle-container">
            <div className="toggle-item">
              <span>Utilities</span>
              <Switch checked={showUtilities} onChange={handleUtilitiesToggle} />
            </div>
            <div className="toggle-item">
              <span>Topography</span>
              <Switch checked={showTopography} onChange={handleTopographyToggle} />
            </div>
          </div>
          {/* Legend */}
          <div className="legend-container">
            <div className="legend-item">
              <span className="legend-color legend-water"></span>
              Water
            </div>
            <div className="legend-item">
              <span className="legend-color legend-sewage"></span>
              Sewage
            </div>
          </div>
        </div>
        <div className="parcels-container">
          <h2>Available Parcels</h2>
          {searchError ? (
            <p>No results found. Please adjust your search criteria.</p>
          ) : filteredPlaces && filteredPlaces.length > 0 ? (
            <ul>
              {filteredPlaces.map((place, index) => (
                <li
                  key={`${place.h}-${index}`}
                  onClick={() => handlePlaceClick(place)}
                  style={{ marginBottom: '10px', cursor: 'pointer' }}
                >
                  <h3>{place.Full_Address}</h3>
                  <p>List Price: ${place.List_Price?.toLocaleString()}</p>
                  <p>Acreage: {place.Lot_Acreage ? place.Lot_Acreage.toFixed(2) : 'N/A'}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No results found. Please adjust your search criteria.</p>
          )}
        </div>
      </div>
      {selectedPlace && (
        <ResultPopup
          place={selectedPlace}
          onClose={handleClosePopup}
          token={localStorage.getItem('token')}
          userStatus={userStatus}
        />
      )}
    </div>
  );
};

export default SearchResults;
