import React from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import logo from './logo.png';

const Navbar = ({ user, setUser }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear user data from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setUser(null);
    // Redirect to home or login page
    navigate('/');
  };

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: "#FFFFFF",
        color: "#000000",
        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
        zIndex: 10000,
      }}
    >
      <Toolbar>
        <Link to="/" style={{ marginRight: 'auto' }}>
          <img src={logo} alt="Logo" style={{ height: '40px' }} />
        </Link>
        {user ? (
          <div>
            <Button
              style={{
                color: '#000000',
                textTransform: 'none',
              }}
            >
              {user}
            </Button>
            <Button
              onClick={handleLogout}
              style={{
                color: '#000000',
                textTransform: 'none',
              }}
            >
              Logout
            </Button>
          </div>
        ) : (
          <Button 
            component={Link}
            to="/signin"
            style={{ 
              color: '#000000', 
              textTransform: 'none',
            }}
          >
            Sign In
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
