import React, { useEffect, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  LayerGroup,
  GeoJSON,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon from './marker-icon.png'; // Ensure this path is correct

// Custom marker icon
const customIcon = new L.Icon({
  iconUrl: markerIcon,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
});

const MapInterface = ({ places, showUtilities, showTopography, onMarkerClick }) => {
  const [sewerData, setSewerData] = useState(null);
  const [waterData, setWaterData] = useState(null);

  useEffect(() => {
    if (showUtilities) {
      // Fetch utility data
      fetch('/Sewer_Main.geojson')
        .then((response) => response.json())
        .then((data) => setSewerData(data))
        .catch((error) => console.error('Error fetching Sewer_Main.geojson:', error));

      fetch('/Water_Main.geojson')
        .then((response) => response.json())
        .then((data) => setWaterData(data))
        .catch((error) => console.error('Error fetching Water_Main.geojson:', error));
    } else {
      setSewerData(null);
      setWaterData(null);
    }
  }, [showUtilities]);

  const validPlaces = places.filter((place) => {
    const lat = Number(place.Latitude);
    const lng = Number(place.Longitude);
    return !isNaN(lat) && !isNaN(lng) && lat !== 0 && lng !== 0;
  });

  const defaultPosition = validPlaces.length > 0
    ? [Number(validPlaces[0].Latitude), Number(validPlaces[0].Longitude)]
    : [36.0687, -94.1732]; // Default to northwest Arkansas

  return (
    <MapContainer center={defaultPosition} zoom={13} style={{ height: '100%', width: '100%' }}>
      {/* Base Layer */}
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      {/* Topographic Layer */}
      {showTopography && (
        <TileLayer
          url="https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}"
          attribution="Map courtesy of the U.S. Geological Survey"
          maxNativeZoom={16} // Maximum zoom level provided by the tile server
          maxZoom={20} // Maximum zoom level allowed in the map
        />
      )}


      {/* Markers for valid places */}
      {validPlaces.map((place, index) => (
        <Marker
          key={`${place.h}-${index}`}
          position={[Number(place.Latitude), Number(place.Longitude)]}
          icon={customIcon}
          eventHandlers={{
            click: () => onMarkerClick(place),
          }}
        />
      ))}

      {/* Utility Layers */}
      {showUtilities && (
        <LayerGroup>
          {sewerData && (
            <GeoJSON data={sewerData} style={{ color: 'green' }} />
          )}
          {waterData && (
            <GeoJSON data={waterData} style={{ color: 'blue' }} />
          )}
        </LayerGroup>
      )}

      {/* Fit map bounds to places */}
      <MapZoomToFitBounds places={validPlaces} />
    </MapContainer>
  );
};

const MapZoomToFitBounds = ({ places }) => {
  const map = useMap();

  useEffect(() => {
    if (places.length > 0) {
      const bounds = L.latLngBounds(places.map((place) => [Number(place.Latitude), Number(place.Longitude)]));
      if (bounds.isValid()) {
        map.fitBounds(bounds, { padding: [50, 50] });
      }
    }
  }, [places, map]);

  return null;
};

export default MapInterface;
